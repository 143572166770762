import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import gtConfig from "../../config.js";
import { Box, Dialog, DialogActions, DialogTitle, Typography, Modal } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { uploadFileModalStyle, validationIssueModalStyle } from "../../styles.js";

const FileUploadModal = ({ isOpen, onClose} ) => {
  
  const { authState, oktaAuth } = useOktaAuth();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileType, setFileType] = useState('Munis_OS');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);


  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
    event.target.value = '';
    setSubmitDialogOpen(true);
  };

  const handleFileTypeChange = (event) => {
    console.log(event.target.value);
    setFileType(event.target.value);
  };

  function showSnackBar(msg, severity){
    setSnackBarMsg(msg);
    setSnackBarSeverity(severity);
    handleOpenSnackBar();
  }


  const handleCloseSnackBar = () => {
      setOpenSnackBar(false);
  };

  const handleOpenSnackBar = () => {
      setOpenSnackBar(true);
  };

  const postFile = () => {
    setSubmitDialogOpen(false);
    if (uploadedFile == null) {
      showSnackBar(`A file was not chosen. Please upload and try again."`, 'error');
      console.log("File is null");
    } else if (fileType === null || fileType === "") {
      showSnackBar(`A File Type was not specified.`, 'error');
      console.log("fileType is null or empty");
    } else if (uploadedFile.type !== "application/pdf") {
      showSnackBar('The chosen file is not a PDF.', 'error');
      console.log("The chosen file is not a PDF.");
    } else {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      const accessToken = oktaAuth.getAccessToken();
      
      const url = gtConfig.ground_truth_back_end.fileUploadUrl + "?file_type=" + fileType;
      axios
        .post(
            url,
            formData,
            {
              headers: {
              'Content-Type': 'application/pdf',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          showSnackBar(response.data.message, 'success');
          setUploadedFile(null);
        })
        .catch((error) => {
          console.error(error);
          if ( error.response && error.response.data.errorMessage) {
            showSnackBar(error.response.data.errorMessage, 'error');
          } else {
            showSnackBar(`An error occurred while uploading the file.${error}`, 'error');
          }
        }).finally(() => {
          setUploadedFile(null);
        });
    }
  };
 
  return (
    <>
        <Modal 
          open={isOpen} 
          onClose={onClose}
          aria-labelledby="Validation Issues"
          aria-describedby="Validation Issues"
        >
            <Box sx={{...uploadFileModalStyle}}>
                <Snackbar
                    open={openSnackBar}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Top center position
                    autoHideDuration={3000} // Auto hide after 3 seconds
                >
                    <Alert
                        onClose={handleCloseSnackBar}
                        message="Snackbar from right div"
                        severity={snackBarSeverity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackBarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={submitDialogOpen}
                    keepMounted
                    onClose={ () => setSubmitDialogOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth={false}
                >
                    <DialogTitle>
                        {
                            <span>
                             Submit Following Document:
                             <br />
                             <br />
                             <Typography><strong>Name:</strong> {uploadedFile?.name} </Typography>
                             <Typography><strong>Type:</strong> {fileType}</Typography>
                            </span>
                        }

                    </DialogTitle>
                    <DialogActions>
                    <Button sx={{ color: "red" }} onClick={ () => setSubmitDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "green" }}
                        onClick={postFile}
                    >
                        Submit
                    </Button>
                    </DialogActions>
                </Dialog>
            <Box
                sx={{
                // width: "100vw",
                paddingTop: "20px",
                display: "flex",
                // justifyContent: "center",
                }}
            >
                <Box sx={{ margin:"10px"}}>
                    <Box sx={{marginBottom: "20px"}}>
                        <Typography color="error"><strong>Note:</strong> Uploading Document Will Automatically Trigger The Extraction</Typography>
                    </Box>
                    <Box  sx={{display: "flex", width: "270px"}}>
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                        <Select
                            labelId="fileType-label"
                            id="fileType-select"
                            value={fileType}
                            label="File Type"
                            onChange={handleFileTypeChange}
                            sx={{
                            width: "200px",
                            height: "40px",
                            }}
                        >
                        {gtConfig.upload_filetypes.map((type, index) => (
                            <MenuItem key={type} sx={{ fontSize: "20px" }} value={type}>
                            {type}
                            </MenuItem>
                        ))}
                        </Select>
                        </FormControl>
                        <input 
                            accept="*"
                            style={{ display: 'none', width: '0' }}
                            id="upload-button-file"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="upload-button-file"  style={{height:'40px'}}>
                        <Button variant="contained" component="span" 
                            style={{height:'40px', marginLeft: '1px'}}>
                            <FontAwesomeIcon icon={faUpload} />
                        </Button>
                        </label>
                    </Box>
                </Box>
            </Box>
        </Box>
      
      </Modal>
    </>
  );
};

export default FileUploadModal;
