import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import logo from '../../assets/header/logo.png'
import aixtra_txt from '../../assets/header/aixtra_txt.png'
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { useOktaAuth } from '@okta/okta-react';
import SearchDocumentComponent from './SearchDocumentComponent'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import NotificationComponent from './NotificationComponent';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useTheme} from "@mui/material/styles";
import gtConfig from './../../config.js';
import { fetchPdfRedirectUrl } from '../../services';
import FileUploadModal from './FileUploadModal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faList } from '@fortawesome/free-solid-svg-icons';
import ActionMenu from './ActionMenu.jsx';
import { Divider, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import NightModeToggle from './NightModeToggle.jsx';



function Header({headerHeight, textDivs, divIdText, loadPdfprogress, searchFeatureChecked, showloadPdfprogress, selectedFile, setSearchFeatureChecked, 
    confirmedBy, confirmedDate, lastModified, isExternalUse, savedBy, savedDate, setValidationIssueModalOpen, setLockDocumentModalOpen, 
    isPathFinderEnable, handleShowOcr, middlePanelContentType}
    ) {
    // State to handle profile menu
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState(new Set());

    const theme = useTheme();

    const profileOpen = Boolean(profileAnchorEl);
    const handleProfileClick = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    // state to handle extra profile details
    const [extraDetailsEnabled, setExtraDetailsEnabled] = useState(null);
    function toggleExtraDetails() {
        setExtraDetailsEnabled(!extraDetailsEnabled);
    }

    // state to handle action menu
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const actionOpen = Boolean(actionAnchorEl);
    const handleActionClick = (event) => {
        setActionAnchorEl(event.currentTarget);
    };
    const handleActionClose = () => {
        setActionAnchorEl(null);
    };

    // state to handle notification menu
    const [notifAnchorEl, setNotifAnchorEl] = useState(null);
    const notifOpen = Boolean(notifAnchorEl);
    const handleNotifClick = (event) => {
        setNotifAnchorEl(event.currentTarget);
    };
    const handleNotifClose = () => {
        setNotifAnchorEl(null);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }


    // auth
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
        } else {
        oktaAuth.getUser().then((info) => {
            setUserInfo(info);
        });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    // const logout = async () => oktaAuth.signOut();

    
    useEffect(() => {
        if (showloadPdfprogress === true){
            const newSet = new Set([...notifications, 'DOC_RENDER'])
            setNotifications(newSet);
        } 
        if (showloadPdfprogress == false){
            notifications.delete('DOC_RENDER');
            const newSet = new Set([...notifications].filter(el => el !=='DOC_RENDER'));
            setNotifications(newSet);
        }
    
    },[showloadPdfprogress]);

    if (!authState) {
        return (
            <div>Loading...</div>
        );
    }

    const handleSearchFeatureChange = (event) => {
        setSearchFeatureChecked(event.target.checked);
    };

    const downloadPdfToLocal = () => {
        if (! selectedFile){
            console.log(`File not selected to download`);
            return
        }
        async function getPdf() {
            try{
                let pdfUrl = null;
                if ( window.location.href.includes("http://localhost")) {
                    pdfUrl = window.location.href.split('/').slice(0, 3).join('/') + '/pdfs/' + selectedFile;
                } else {
                    const pdfRedirectUrl = gtConfig.ground_truth_back_end.signedPdfUrl + "?no_redirect&file_name=" + selectedFile;          
                    let redirectUrl  = fetchPdfRedirectUrl(pdfRedirectUrl, authState, oktaAuth);
                    pdfUrl = await redirectUrl;
                }
                
                if (pdfUrl === undefined || pdfUrl === null){
                    throw new Error('error while getting pdf file');
                }

                const response = await fetch(pdfUrl);
                const blob = await response.blob();
                
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', selectedFile);
                document.body.appendChild(link);
                link.click();
                return
            } catch (error) {
                console.error(error);
            }
        }
        getPdf()
    }
    
    const logout = async () => oktaAuth.signOut();

    return (
        <>
        <Box className="header-display" sx={{display:"flex", maxHeight: `${headerHeight}px` , minHeight:`${headerHeight}px`, justifyContent: "space-between"}}>
          {!isExternalUse &&  <Box display="flex" alignItems="center" marginLeft={2}>
                <img src={logo} alt="Logo"/>
                <Divider
                    orientation="vertical" 
                    flexItem 
                    sx={{ mx: 2, width: '2px', height: '40px'}}
                />
                <Box 
                    component="img"  
                    sx={{flexDirection:"row", paddingLeft: "20px", width: '120px', height: '20px', marginRight: '50px'}}
                    src={aixtra_txt}
                >
                </Box>
            </Box>
            }
            { selectedFile &&
            <Box  sx={{ marginLeft:  isExternalUse ? "400px": "120px", width:"580px"}}>
            {/* <Box sx={{flexDirection:"row", marginLeft: "auto", width:"650px", marginLeft:'150px'}}> */}
            {/* <Box  sx={{flexDirection:"row", marginLeft: "100px", width:"360px"}}> */}
               {!isExternalUse && <Typography variant='body1' fontWeight={700}>{selectedFile}</Typography> }
                <Box display="flex" alignItems="center">
                    <SearchDocumentComponent 
                        textDivs={textDivs}
                        divIdText={divIdText}
                        searchFeatureChecked={searchFeatureChecked}
                        headerHeight={headerHeight}
                        showloadPdfprogress={showloadPdfprogress}
                    />
                    { selectedFile &&  
                    <Button
                        id=''
                        sx={{height: "22px", marginLeft: "5px"}}
                            variant="contained" 
                            size="small"
                            onClick={downloadPdfToLocal}>
                                Download
                    </Button>
                    }
                    <ToggleButtonGroup
                        color="primary"
                        value={middlePanelContentType}
                        exclusive
                        onChange={handleShowOcr}
                        aria-label="Platform"
                        sx={{marginLeft: "5px"}}
                    >
                        <ToggleButton value="pdf" size='small' sx={{height:'22px'}}>PDF</ToggleButton>
                        <ToggleButton value="ocr" size='small' sx={{height:'22px'}}>OCR</ToggleButton>    
                    </ToggleButtonGroup>
                </Box>
            </Box>
            }

            <Box sx={{display:"flex", height: "inherit", marginTop:"3px",marginRight: "5px", marginLeft: "auto",  width:"300px"}}>
                <Box sx={{ flexDirection: "row"}}>
                    {selectedFile && 
                        <Box sx={{display: 'flex', marginLeft:'1px', marginBottom:"0px", marginTop: ''}}>
                            <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}>
                                { lastModified && 
                                <Typography variant='caption' fontSize={10} sx={{fontWeight:'600', margin:"0 5px"}}> Last Extraction On:
                                    <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}> {lastModified}</Typography>
                                </Typography> }
                                <br/>
                                { savedBy && 
                                <Typography variant='caption' fontSize={10} sx={{fontWeight:'600', margin:"0 5px"}}> Saved By:
                                    <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}> {savedBy}</Typography>
                                </Typography> } 
                                { savedDate && 
                                <Typography variant='caption' fontSize={10} sx={{fontWeight:'600', margin:"0 5px"}}> Date:
                                    <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}> {savedDate}</Typography>
                                </Typography> }
                                <br/>
                                { confirmedBy && 
                                <Typography variant='caption' fontSize={10} sx={{fontWeight:'600', margin:"0 5px"}}>  Confirmed By:
                                    <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}> {confirmedBy}</Typography>
                                </Typography> }
                                { confirmedDate && 
                                <Typography variant='caption' fontSize={10} sx={{fontWeight:'600', margin:"0 5px"}}>  Date:
                                    <Typography variant='caption' fontSize={10} sx={{fontWeight:'500', marginBottom: 0, marginTop: 0}}> {confirmedDate}</Typography>
                                </Typography> }
                            
                            </Typography><br/>
                        </Box>
                       }
                    </Box>
                </Box>    
                <Box sx={{ display: 'flex', minWidth:'250px', justifyContent: "flex-end"}}>
                    <Box sx={{flexDirection:"row", marginRight:'5px',justifyContent: "flex-end"}}>
                        <Badge badgeContent={notifications.size} color="primary" sx={{marginTop:'18px'}}>
                            <NotificationsIcon 
                                color="action" 
                                aria-controls={notifOpen ? 'notif-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={notifOpen ? 'true' : undefined}
                                onClick={handleNotifClick}
                            />
                        </Badge>
                        <Menu
                            id="notif-menu"
                            classes={{paper: "notif-menu"}}
                            anchorEl={notifAnchorEl}
                            open={notifOpen}
                            onClose={handleNotifClose}
                            MenuListProps={{
                            'aria-labelledby': 'notif-btn',
                            }}
                            >
                            <NotificationComponent
                                loadPdfprogress={loadPdfprogress}
                                notifications={notifications}
                            ></NotificationComponent>
                        </Menu>
                    </Box>
                    <Box sx={{flexDirection:"row"}}>
                        <hr className="header-hr" style={{height: "55px"}}/>
                    </Box> 
                    <Box sx={{flexDirection:"row", height: "60px", marginTop:"10px", width:"55px",paddingRight:"7px", marginRight:"4px"}}>
                        <Button
                            id="menu-btn"
                            onClick={handleActionClick}
                        >
                            <FontAwesomeIcon icon={faList} size="2x"  color="grey"/>
                        </Button>
                        <Menu
                            id="action-menu"
                            classes={{paper: "action-menu"}}
                            anchorEl={actionAnchorEl}
                            open={actionOpen}
                            onClose={handleActionClose}
                            MenuListProps={{
                            'aria-labelledby': 'action-btn',
                            }}
                            >
                                <Box className="action-details">
                                    <ActionMenu 
                                        setFileUploadModalOpen={setFileUploadModalOpen}
                                        setValidationIssueModalOpen={setValidationIssueModalOpen}
                                        setLockDocumentModalOpen={setLockDocumentModalOpen}
                                        handleActionClose={handleActionClose}
                                        isPathFinderEnable={isPathFinderEnable}
                                    />
                                </Box>
                            
                        </Menu>
                    </Box>
                    <Box sx={{flexDirection:"row"}}>
                        <hr className="header-hr" style={{height: "55px"}}/>
                    </Box>
                    <Box sx={{flexDirection:"row", height: "60px", width:"55px", marginRight:"inherit"}}>
                        <Button id="profile-btn" 
                            className={profileOpen ? "profile-btn profile-btn-active" : "profile-btn"}
                            sx={{ height: "inherit", width:"inherit", backgroundColor: "var(--color-white)"}}
                            aria-controls={profileOpen ? 'profile-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={profileOpen ? 'true' : undefined}
                            onClick={handleProfileClick}>
                                <p className="profile-text">{authState.idToken.claims.FirstName[0]}{authState.idToken.claims.LastName[0]}</p>
                        </Button>
                        <Menu
                            id="profile-menu"
                            classes={{paper: "profile-menu"}}
                            anchorEl={profileAnchorEl}
                            open={profileOpen}
                            onClose={handleProfileClose}
                            MenuListProps={{
                            'aria-labelledby': 'profile-btn',
                            }}
                            >
                            <div className="inner-icon">
                                <p className="initials-text">{authState.idToken.claims.FirstName[0]}{authState.idToken.claims.LastName[0]}</p>
                            </div>

                            <div className="profile-details">
                                {authState.isAuthenticated && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: "12px" }}>
                                            <Typography variant="h6" fontWeight={600} sx={{ marginRight: 2 }}>
                                                {authState.idToken.claims.FirstName} {authState.idToken.claims.LastName}
                                            </Typography>
                                            <Typography variant="h7">
                                                <Link href="/profile" target="_blank" rel="noopener">
                                                    Profile
                                                </Link>
                                            </Typography>
                                        </Box>
                                )}
                                <p>
                                    <FormControlLabel 
                                        control={<Switch 
                                            onChange={handleSearchFeatureChange} 
                                            checked={searchFeatureChecked}
                                        />} 
                                        label={<Typography variant="h7">Enable Search</Typography>} 
                                        title='Reload the document when Enable the Search'
                                    />
                                </p>                            

                                {
                                    extraDetailsEnabled ? 
                                    <div className="extra-details-container">
                                        <p className="extra-details-key">Department: <span className="extra-details-info">{authState.idToken.claims.Department}</span></p>
                                    </div>
                                    : <></>
                                }
                                <a className="more-details" onClick={toggleExtraDetails}>{extraDetailsEnabled ? "Less" : "More"}</a>
                                
                            </div>
                            { !isExternalUse && <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                    <NightModeToggle />
                                    <Tooltip title={"Sign Out"}>
                                        <IconButton onClick={logout} color={'secondary'}>
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} />
                                        </IconButton>
                                    </Tooltip>
                            </Box>  }        
                        </Menu>
                    </Box>
                </Box>                                    
            </Box>
        {fileUploadModalOpen && <FileUploadModal
                            isOpen={fileUploadModalOpen}
                            onClose={() => setFileUploadModalOpen(false)}
        />}
        </>
    );
}

export default Header